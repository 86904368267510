/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"

import Header from "./header"
import "./layout.css"

const Layout = ({ children }) => {
  return (
    <>
      <Header />
      <div className="flex flex-col justify-between h-screen">
        <main className="h-full">{children}</main>
        <footer className="text-center text-sm py-3 text-gray-300">
          <p className="font-nunitosans">Adelaide, South Australia</p>
          <p className="font-nunitosans">
            © {new Date().getFullYear()}, Hyprlink
          </p>
        </footer>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
